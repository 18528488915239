import React from 'react';
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import Paragraph1 from '../Type/Paragraph1'
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import Form from './Form'

const freePPSR = (props) => (
    <StaticQuery
        query={graphql`
            query  {

                governmentImage: file(relativePath: { eq: "ppsr/australian-government-afsa.png" }) {
                childImageSharp {
                    fixed(width: 333, height: 80) {
                    ...GatsbyImageSharpFixed
                    }
                }
                }

                ppsrImage: file(relativePath: { eq: "ppsr/ppsr.png" }) {
                childImageSharp {
                    fixed(width: 173, height: 80) {
                    ...GatsbyImageSharpFixed
                    }
                }
                }

                whatsincludedImage: file(relativePath: { eq: "ppsr/whats-included.png" }) {
                childImageSharp {
                    fixed(width: 355, height: 217) {
                    ...GatsbyImageSharpFixed
                    }
                }
                }

            }
        `}
        render={data => {

            return (
                <>
                    <SectionPageMargin marginBottom="0px">
                        <TextBlock>
                            <Paragraph1>
                                Our car verification check shows the latest information from the official Government PPSR &
                                NEVDIS databases. We show you the stolen vehicle check, finance check, write-off history
                                and also provide your with the official PPSR certificate (PDF).
                            </Paragraph1>
                            <Paragraph1>
                                The service is currently only available for VIC, NSW and QLD registered cars. Free searches
                                are limited to 2 searches per person / email address.
                            </Paragraph1>
                        </TextBlock>
                    </SectionPageMargin>

                    <SectionPageMargin marginTop="0px">
                        <Grid2 style={{ paddingTop: '40px', paddingBottom: '30px' }}>
                            <GridItem order="2">
                                <Form source={props.source} carAlt={props.carAlt} backLink={props.backLink}/>
                            </GridItem>
                            <GridItem order="1" align="left">
                                <Img fixed={data.whatsincludedImage.childImageSharp.fixed} /><br />
                                <Img fixed={data.governmentImage.childImageSharp.fixed} /><br />
                                <Img fixed={data.ppsrImage.childImageSharp.fixed} /><br />
                                <p style={{ paddingLeft: '10px' }}>
                                    Our free PPSR check service is currently available for the following brands:
                                    Aston Martin, Audi, Bentley, BMW, Ferrari, Lamborghini, Land Rover / Range Rover,
                                    Mercedes-Benz, Porsche, Maserati, McLaren, Rolls Royce and Tesla.
                                </p>
                            </GridItem>
                        </Grid2>
                    </SectionPageMargin>
                </>
            )
        }}
    />
)


export default freePPSR;

const TextBlock = styled.div`
  max-width: 800px;
`;
