import React from 'react';
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import HomeIntro from '../components/HomeIntro/HomeIntro'
import Quote from '../components/Quotes/Quotes'
import BestOfBothWorlds from '../components/BestOfBothWorlds/BestOfBothWorlds'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import FreePPSR from '../components/FreePPSR/FreePPSR'

const ppsrPage = ({location}) => (

      <Layout>
        
        <SEO  title="Free PPSR check for prestige cars" 
              description="For a limited time only we are offering free PPSR / VIN checks and PPSR Certificates for a selection of prestige car brands."
              location={location}
        />
        <PageHeader
              title="Free PPSR check for prestige cars"
              text="Sorry, this service is no longer available."
            />
{/* 
        <FreePPSR 
          source={'Summon'}
        /> */}

        {/* <HomeIntro />  */}
      
        <Quote />

        <BestOfBothWorlds />

      </Layout>

    );


export default ppsrPage

