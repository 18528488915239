import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';


const ContactTracker = ({ trackAction, trackLabel, hrefTo, children, hrefType }) => {

    const handleClick = () => {

        trackCustomEvent({
            category: "Conversion",
            action: trackAction,
            label: trackLabel
        });

        const a = document.createElement("a");
        a.href = hrefType + hrefTo;
        a.click();

    }

     return (
         <Button title={hrefType + " " + hrefTo} onClick={() => handleClick()}>{children}</Button>
   )
 } 
 
 export default ContactTracker;

 const Button = styled.button`

    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: none;
    font-weight: normal;
    font-size: 1rem;


    letter-spacing: -0.05rem;
    &:hover {
        opacity: 0.9;
    }
  `




