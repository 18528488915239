import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FormControl from '../Form/FormControl';
import ActionControl from '../Form/ActionControl';

import Input from '../Form/Input';
import Select from '../Form/Select';
import Checkbox from '../Form/Checkbox';
import InputError from '../Form/InputError';
import Button from '../Layout/Button/Button';
import Paragraph1 from '../Type/Paragraph1';
import styled from 'styled-components';
import { Link } from "gatsby";
import ContactTracker from '../ContactTracker/ContactTracker';


class ppsrForm extends Component {

    constructor(props) {
        super(props);

        this.regVinEl = React.createRef();
        this.emailEl = React.createRef();
        this.stateEl = React.createRef();
        this.makeEl = React.createRef();

        //this.buySellEl = React.createRef();
        this.recaptchaRef = React.createRef();

        //this.carType = props.carType;
        //this.closePop = props.closePop;

        this.state = {
            formSubmissionSuccessful: false,
            make: "",
            state: "",
            email: "",
            regVin: "",
            formError: "",
            regVinError: "",
            emailError: "",
            stateError: "",
            makeError: "",
            isVerified: false,
            capatchaValue: "",
            selectedOption: "",
            processing: false,
            processingError: null,
        }

        this.regVinValidation = ["regVinError",
            { required: "Enter a Rego or VIN" }];

        this.emailValidation = ["emailError",
            {
                required: "Enter your email address",
                email: "Enter a valid email address"
            }];

        this.makeValidation = ["makeError",
            { required: "Select a Make" }];

        this.stateValidation = ["stateError",
            { required: "Select a State" }];


        this.submitHandler = this.submitHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    // handleOptionChange = changeEvent => {
    //     this.setState({
    //         selectedOption: changeEvent.target.value
    //     });
    //     this.validate(changeEvent.target.value, this.buySellValidation);
    // };


    onChangeCaptcha = (value) => {
        if (value !== null) {
            this.setState({ isVerified: true, capatchaValue: value });
        } else {
            this.setState({ isVerified: false, capatchaValue: null, formError: "" });
        }
    }

    resetForm = () => {
        this.setState({
            isVerified: false, 
            capatchaValue: null, 
            formError: "",
            processingError: null,
            formSubmissionSuccessful: false, 
            processing: false
        })
    }

    submitHandler = (event) => {

        event.preventDefault(); // stop form loading new page

        const email = this.state.email
        const make = this.state.make;
        const regVin = this.state.regVin;
        const state = this.state.state;
        const valuation = this.state.valuation;

        var errors = 0;
        errors += this.validate(email, this.emailValidation);
        errors += this.validate(make, this.makeValidation);
        errors += this.validate(regVin, this.regVinValidation);
        errors += this.validate(state, this.stateValidation);

        if (errors !== 0) return errors;

        if (!this.state.isVerified) return; // reCaptacha
        trackCustomEvent({
            category: "Conversion",  // how to we want to track this in GA?
            action: "Form Submission",
            label: "PPSR: " + this.make
        });

        let formBody = {
            email: email,
            make: make,
            regoVIN: regVin,
            state: state,
            valuation: valuation,
            source: this.props.source
        }


        this.setState({ formSubmissionSuccessful: false, processing: true, processingError: null }); // shoud add a spinner

        fetch(`https://carxapi.com/ppsr/free`, {
        //fetch(`http://172.16.16.210:8081/ppsr/free`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(formBody),
            headers: {
                'Capatcha': this.state.capatchaValue,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                this.setState({ formError: "Unfortunately we were unable to send your request." })
                window.grecaptcha.reset();

                throw new Error('Failed!');
            }
            return res.json();
        })
            .then(resData => {

                if(resData.error) {
                   
                    this.setState({ 
                        processingError: resData.error,
                        formSubmissionSuccessful: true, 
                        processing: false
                     });
                }
                if(resData.success) {

                    this.setState({ 
                        processingError: null,
                        formSubmissionSuccessful: true, 
                        processing: false
                     });
                }
            })
            .catch(err => {
                console.log(err);
            });

    };

    validate = (value, validation) => {
        var fieldValue = value;
        var errMessage = "";

        if ('email' in validation[1]) {
            let emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) {
                errMessage = validation[1].email;
            }
        }
        if ('required' in validation[1]) {
            if (fieldValue.trim().length === 0) {
                errMessage = validation[1].required;
            }
        }

        this.setState({ [validation[0]]: errMessage });
        return (errMessage === "") ? 0 : 1;
    }

    render() {


        const errorMessage = (
            <>
            <Paragraph1>
                <strong>OPPS! Something went wrong.</strong>
            </Paragraph1>
           
            <Paragraph1>
                <InputError>{this.state.processingError}</InputError>
            </Paragraph1>

            <Paragraph1>
                For a successful search:
                <ol>
                    <li>Make and State must match NEDVIS database.</li>
                    <li>Registration or VIN must match PPSR database.</li>
                    <li>You must be under our search limit threshold. Continuous failed attempts count.</li>
                    <li>You must enter a valid email address to receive the report.</li>
                </ol>
            </Paragraph1>

            <Paragraph1>
                If you having problems, please contact us at: <br />   
                <ContactTracker
                    trackAction="Contact"
                    trackLabel="Email Support"
                    hrefType="mailto:"
                    hrefTo="hello@summon.com.au" >
                    hello@summon.com.au
                </ContactTracker>
            </Paragraph1>

            <Button type="button" onClick={this.resetForm}>Try Again</Button>


            </>
        );

        let confirmationMessage = (
            <>
                <Paragraph1>
                    <strong>Request submitted</strong>
                </Paragraph1>
                <Paragraph1>
                    Thank you for your PPSR request. You will receive an email within the next few minutes with the PPSR certificate.
                </Paragraph1>

                <Paragraph1>
                    If you do not receive the email, please check your spam and junk folders.
                </Paragraph1>

                <Paragraph1>
                    For assistance, please email&nbsp;  
                    <ContactTracker
                        trackAction="Contact"
                        trackLabel="Email Support"
                        hrefType="mailto:"
                        hrefTo="hello@summon.com.au" >
                        hello@summon.com.au
                    </ContactTracker>
                    &nbsp;and include details of your submission (e.g. the registration or VIN) so that we can assist you.
                </Paragraph1>

                {!this.props.backLink &&
                    <Paragraph1>
                        Are you selling a prestige car soon? Request a <Link to="https://value.summon.com.au//">Free Valuation</Link> or learn more
                        about Summon and our <Link to="/sell-with-summon/">unique service</Link> for getting you the best price for your car.
                    </Paragraph1>
                }

                {this.props.backLink && 
                    <>
                        <Paragraph1>
                            For more information about how Summon can help you to sell 
                            your {this.props.carAlt ? this.props.carAlt : 'car'}, please visit <Link to="/">www.summon.com</Link>
                        </Paragraph1>
                        <Paragraph1>
                            <a href={"https://" + this.props.backLink}>{this.props.backLink.text}</a>
                        </Paragraph1>
                    </>
                }
                 

            </>
        );

        if(this.state.processingError) confirmationMessage = errorMessage;

        const processingMessage = (
            <>
            <Paragraph1>
                Please wait a minute, we are checking a few things:
                <ol>
                    <li>Make</li>
                    <li>State</li>
                    <li>Registration / VIN</li>
                    <li>NEDVIS Database</li>
                    <li>PPSR Database</li>
                    <li>Sending Certificate</li>
                </ol>
            </Paragraph1>
            </>);



        const pageHTML = (

            <form onSubmit={this.submitHandler}>

                <FormControl>
                    <LabelContainer>
                        <LabelBold for="make">Car Make</LabelBold>
                    </LabelContainer>
                    <Select
                        type="select"
                        id="make"
                        name="make"
                        ref={this.emailEl}
                        onBlur={(e) => this.validate(e.target.value, this.makeValidation)}
                        onChange={this.handleInputChange}
                        aria-label="Make"
                    >
                        <option value="">Please select</option>
                        <option value="Aston Martin">Aston Martin</option>
                        <option value="Audi">Audi</option>
                        <option value="Bentley">Bentley</option>
                        <option value="BMW">BMW</option>
                        <option value="Ferrari">Ferrari</option>
                        <option value="Lamborghini">Lamborghini</option>
                        <option value="Land Rover">Land Rover / Range Rover</option>
                        <option value="Maserati">Maserati</option>
                        <option value="McLaren">McLaren</option>
                        <option value="Mercedes-Benz">Mercedes-Benz</option>
                        <option value="Porsche">Porsche</option>
                        <option value="Rolls Royce">Rolls Royce</option>
                        <option value="Tesla">Tesla</option>
                    </Select>
                    <InputError style={{ marginTop: '5px' }}>{this.state.makeError}</InputError>
                </FormControl>


                <FormControl>
                    <LabelContainer>
                        <LabelBold for="regVin">Rego or Vin</LabelBold><LabelNote>Either way it's still free</LabelNote>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="regVin"
                        name="regVin"
                        onBlur={(e) => this.validate(e.target.value, this.regVinValidation)}
                        onChange={this.handleInputChange}
                        aria-label="Rego or VIN"
                       
                    />
                    <InputError>{this.state.regVinError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelBold for="state">State of Registration</LabelBold>
                    </LabelContainer>
                    <Select
                        type="select"
                        id="state"
                        name="state"
                        ref={this.stateEl}
                        onBlur={(e) => this.validate(e.target.value, this.stateValidation)}
                        onChange={this.handleInputChange}
                        aria-label="State of Registration"
                    >
                        <option value="">Please select</option>
                        <option value="NSW">New South Wales</option>
                        <option value="QLD">Queensland</option>
                        <option value="VIC">Victoria</option>
                    </Select>
                    <InputError style={{ marginTop: '5px' }}>{this.state.stateError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelBold for="email">Your email</LabelBold><LabelNote> We will email you the results</LabelNote>
                    </LabelContainer>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                        onBlur={(e) => this.validate(e.target.value, this.emailValidation)}
                        onChange={this.handleInputChange}
                        aria-label="Email"
                         />
                    <InputError>{this.state.emailError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelBold for="valuation">Are you selling soon?</LabelBold>
                    </LabelContainer>
                    <p>
                        If you are selling this car, or another prestige car, there is now an 
                        alternative to accepting a low trade-in price or the hassles of selling 
                        privately. Summon can sell your car for you, for a great price.
                    </p>

                    <div style={{display: 'flex'}}>
                        <div style={{marginTop:'5px', marginRight: '10px'}}>
                            <Checkbox
                                type="checkbox"
                                id="valuation"
                                name="valuation"
                                //onBlur={(e) => this.validate(e.target.value, this.regVinValidation)}
                                onChange={this.handleInputChange}
                                aria-label="Request valuation"
                                />
                        </div>
                        <div>
                            Yes, I own a prestige car ($30k+) and would like to request a <strong>free valuation report</strong> from Summon. <br />
                            <div style={{fontSize: '0.8rem', color: '#333333', marginTop:'10px'}}>A Summon team member will contact you to request details about your car.</div>
                        </div>
                    </div>
                </FormControl>



                <div>
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey="6LcJvbYUAAAAAIMoSZgsxjjb69wAQ9OhaIoND4nC"
                        onChange={this.onChangeCaptcha}
                        theme="light"
                        size="normal"
                    />
                </div>

                <ActionControl>
                    <Button type="submit">Submit</Button>
                    <InputError>{this.state.FormError}</InputError>
                </ActionControl>

            </form>
        )

        return (

            <>
                {!this.state.formSubmissionSuccessful && !this.state.processing && pageHTML}
                {!this.state.formSubmissionSuccessful && this.state.processing && processingMessage}
                {this.state.formSubmissionSuccessful && !this.state.processing && confirmationMessage}
            </>

        );
    }

}

export default ppsrForm;

const LabelContainer = styled.div`
    margin-bottom: 10px;
`;

const LabelBold = styled.label`
    font-weight: 600;
    font-size: 1.2rem;
    margin-right: 10px;
`;
const LabelNote = styled.span`
    font-weight: 400;
    font-size: .8rem;
`;







